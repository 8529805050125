// import html2canvas from "html2canvas";
// import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { anchorlistState } from "../redux/anchorlistSlice";
import { scenelistState } from "../redux/scenelistSlice";
import logo from "../assets/Sneakertopia-Logo-Complete-final-2019-08-14-W@4x.png";
import SceneHeader from "../components/SceneHeader";

export default function ViewScene() {
  const scenes = useSelector(scenelistState);
  const anchors = useSelector(anchorlistState);
  const navigate = useNavigate();
  const { docId } = useParams();
  const scene = scenes.find((a) => a.name === docId);

  if (!scene) return <div>Scene not found</div>;

  // async function downloadItem(item: string) {
  //   const domElement = document.getElementById(item);

  //   html2canvas(domElement!, {
  //     // proxy: item.imageUrl,
  //     allowTaint: true,
  //     useCORS: true,
  //   }).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const downloadLink = document.createElement("a");
  //     downloadLink.download = `sneakertopia-ar-${item}-${new Date().toISOString()}.png`;
  //     downloadLink.href = imgData;
  //     downloadLink.click();
  //   });
  // }

  return (
    <div className="flex flex-col p-5 text-white bg-black space-y-7">
      <img
        onClick={() => navigate("/")}
        className="w-[60%] mt-7 mx-auto max-w-[400px] p-5 bg-white bg-opacity-20 rounded-md object-contain cursor-pointer"
        src={logo}
        alt="logo"
      />
      <SceneHeader />
      <div className="flex flex-col items-center justify-center space-y-2">
        <div className="text-2xl text-center">AR Scene: {scene.label}</div>
        <div className="flex-wrap px-5 text-center">
          Click on Art Icon to view story from the Sneakertopia Museum
        </div>
      </div>
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {anchors
          .filter((a) => a.scene === scene.name)
          .map((a) => (
            <div
              key={a.name}
              className="flex flex-col items-center p-5 overflow-hidden text-black bg-white border rounded-md shadow-lg"
            >
              <div
                id={a.name}
                className="flex flex-col items-center p-5 space-y-7"
              >
                {/* <div
                  className="w-full mx-auto cursor-pointer"
                  onClick={() => downloadItem(a.name)}
                >
                  <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={`https://ar.sneakertopia.com/scanner/${a.name}`}
                    viewBox={"0 0 256 256"}
                    id={"QRCode"}
                  />
                </div> */}
                <div className="flex items-center h-full ">
                  <img
                    onClick={() => navigate(`/video/${a.name}`)}
                    className="object-contain cursor-pointer"
                    src={a.imageUrl}
                    alt={a.label}
                  />
                </div>
                <div className="p-3 text-lg lg:text-xl">{a.label}</div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
