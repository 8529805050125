import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

const initialState: AuthType = {
  uid: null,
  email: null,
  displayName: null,
  authIsReady: false,
  admin: false,
  site: false,
};

export type AuthType = {
  uid: string | null;
  email: string | null;
  displayName: string | null;
  admin: boolean;
  authIsReady: boolean;
  site: boolean;
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, action) {
      state.uid = action.payload.uid;
      state.email = action.payload.email;
      state.displayName = action.payload.displayName;
    },
    admin(state, action) {
      state.admin = action.payload;
    },

    logout(state) {
      state.uid = null;
      state.email = null;
      state.displayName = null;
      state.admin = false;
    },
    authReady(state, action) {
      state.uid = action.payload.uid;
      state.email = action.payload.email;
      state.displayName = action.payload.displayName;
      state.authIsReady = true;
    },
    site(state, action) {
      state.site = action.payload;
    },
  },
});

export const { login, admin, logout, authReady, site } = authSlice.actions;

export const authState = (state: RootState) => state.auth;
export default authSlice.reducer;
