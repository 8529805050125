import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { SceneType } from "../typings/SceneType";

const initialState: { scenes: SceneType[] } = { scenes: [] };

const scenelistSlice = createSlice({
  name: "scenelist",
  initialState,
  reducers: {
    updateScenelist(state, action) {
      const sortedScenes = [...action.payload].sort(
        (a, b) => a.order - b.order
      );
      state.scenes = sortedScenes.filter((scene) => scene.active);
    },
  },
});

export const { updateScenelist } = scenelistSlice.actions;

export const scenelistState = (state: RootState) => state.scenelist.scenes;

export default scenelistSlice.reducer;
