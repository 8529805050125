import { useNavigate } from "react-router-dom";
import logo from "../assets/Sneakertopia-Logo-Complete-final-2019-08-14-W@4x.png";
import download_appstore from "../assets/download_appstore.png";
import download_googleplay from "../assets/download_googleplay.png";

export default function Home() {
  const navigate = useNavigate();

  const isiPhone = () => {
    return navigator.userAgent.match(/iPhone/i);
  };

  const isiPad = () => {
    return navigator.userAgent.match(/iPad/i);
  };

  const isAndroid = () => {
    return navigator.userAgent.match(/Android/i);
  };

  return (
    <div className="flex flex-col items-center justify-between flex-1 w-screen h-full max-h-screen text-white bg-black space-y-7">
      <img
        onClick={() => navigate("/")}
        className="w-[60%] my-5 mx-auto max-w-[400px] p-5 bg-white bg-opacity-20 rounded-md object-contain cursor-pointer"
        src={logo}
        alt="logo"
      />
      <div className="flex flex-col items-center p-5 mx-auto space-y-5 border rounded-md w-full sm:w-[60%] max-w-[400px]">
        <div className="text-xl text-center">
          Scan QR codes to reveal Sneakertopia Stories, or explore the scenes
          here. No download required.
        </div>

        <button
          className="w-full p-5 text-white bg-blue-500 rounded-md"
          onClick={() => navigate("/scanner")}
        >
          Scan Story QR Codes
        </button>

        <button
          className="w-full p-5 text-white bg-blue-500 rounded-md"
          onClick={() => navigate("/scenes")}
        >
          Explore Story Scenes
        </button>
      </div>

      {(isiPhone() || isiPad() || !isAndroid()) && (
        <div className="flex flex-col items-center justify-center w-full sm:w-[60%] max-w-[400px]">
          <a
            className="p-5 border rounded-md hover:opacity-50"
            href="https://apps.apple.com/us/app/sneakertopia-museum/id1588216615"
          >
            <div className="text-xl text-center">
              For the full Augmented Reality experience on iOS, scan murals with
              the Sneakertopia Museum app.
            </div>
            <img
              src={download_appstore}
              alt="appstore"
              className="object-fit "
            />
          </a>
        </div>
      )}

      <div className="flex items-center p-5 mt-auto space-x-2">
        <a href="https://sneakertopia.com" target="_blank" rel="noreferrer">
          Sneakertopia
        </a>
        <a
          href="https://sneakertopia.com/privacy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy
        </a>
        <a
          href="https://sneakertopia.com/terms"
          target="_blank"
          rel="noreferrer"
        >
          Terms
        </a>
      </div>
    </div>
  );
}
