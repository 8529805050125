import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { scenelistState } from "../redux/scenelistSlice";
import { SceneType } from "../typings/SceneType";
import { useState } from "react";
// import logo from "../assets/Sneakertopia-Logo-Complete-final-2019-08-14-W@4x.png";
export default function SceneHeader() {
  const [menuOpen, setMenuOpen] = useState(false);
  const scenes: SceneType[] = useSelector(scenelistState);
  const navigate = useNavigate();
  return (
    <div className="flex flex-col space-y-1 text-white bg-black ">
      <button
        className="px-3 py-2 text-white bg-gray-500 rounded-md"
        onClick={() => setMenuOpen(!menuOpen)}
      >
        {menuOpen ? "Close Scene Selector" : "Open Scene Selector"}
      </button>

      {menuOpen && (
        <div className="flex flex-col space-y-1">
          {scenes.map((a) => (
            <div
              onClick={() => {
                setMenuOpen(false);
                navigate(`/scene/${a.name}`);
              }}
              key={a.name}
              className="flex flex-row items-center justify-between h-full p-2 space-x-2 text-black bg-white border rounded-md shadow-lg cursor-pointer hover:opacity-50 "
            >
              <div className="flex-wrap h-full mr-auto text-base">
                {a.label}
              </div>
              <img
                className="object-contain w-12 h-12"
                src={a.imageUrl}
                alt={a.label}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
