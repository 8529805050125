import { useEffect } from "react";
import { db } from "./firebase.config";
import { AnchorType } from "./typings/AnchorType";
import { useDispatch } from "react-redux";
import { updateAnchorlist } from "./redux/anchorlistSlice";
import { collection, onSnapshot, query } from "firebase/firestore";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { SceneType } from "./typings/SceneType";
import { updateScenelist } from "./redux/scenelistSlice";

import ExploreScenes from "./pages/ExploreScenes";
import Home from "./pages/Home";

import ViewScene from "./pages/ViewScene";
import Scanner from "./pages/Scanner";

export default function Main() {
  const dispatch = useDispatch();

  useEffect(() => {
    const getAnchorlist = async () => {
      const q = query(collection(db, "anchors"));
      const unsub = onSnapshot(q, (querySnapshot) => {
        const anchorload: AnchorType[] = [];
        querySnapshot.forEach((doc) => {
          const a = doc.data();

          anchorload.push({
            id: a?.id || "",
            name: a?.name || "",
            label: a?.label || "",
            description: a?.description || "",
            image: a?.image || "",
            video: a?.video || "",
            scene: a?.scene || "",
            imageUrl: a?.imageUrl || "",
            videoUrl: a?.videoUrl || "",
            order: a?.order || 0,
            width: a?.width || 0,
            height: a?.height || 0,
            orientation: a?.orientation || "",
            active: a?.active || false,
          });
        });

        dispatch(updateAnchorlist(anchorload));
        return () => unsub();
      });
    };
    getAnchorlist();
  }, [dispatch]);

  useEffect(() => {
    const getScenelist = async () => {
      const q = query(collection(db, "scenes"));
      const unsub = onSnapshot(q, (querySnapshot) => {
        const anchorload: SceneType[] = [];
        querySnapshot.forEach((doc) => {
          const a = doc.data();
          anchorload.push({
            id: a?.id || "",
            order: a?.order || 0,
            name: a?.name || "",
            label: a?.label || "",
            tagline: a?.tagline || "",
            description: a?.description || "",
            artists: a?.artists || "",
            image: a?.image || "",
            imageUrl: a?.imageUrl || "",
            room: a?.room || "",
            active: a?.active || false,
          });
        });

        dispatch(updateScenelist(anchorload));
        return () => unsub();
      });
    };
    getScenelist();
  }, [dispatch]);

  return (
    <BrowserRouter>
      <div className="w-screen h-screen overflow-y-scroll text-white bg-black">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/scenes" element={<ExploreScenes />} />
          <Route path="/video/:docId" element={<Scanner scan={false} />} />
          <Route path="/scene/:docId" element={<ViewScene />} />
          <Route path="/scene" element={<ViewScene />} />
          {/* <Route path="/create" element={<Create />} /> */}
          <Route path="/scanner" element={<Scanner scan={true} />} />
          <Route path="/scanner/:docId" element={<Scanner scan={true} />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}
