import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { scenelistState } from "../redux/scenelistSlice";
import { SceneType } from "../typings/SceneType";
import logo from "../assets/Sneakertopia-Logo-Complete-final-2019-08-14-W@4x.png";
export default function ExploreScenes() {
  const scenes: SceneType[] = useSelector(scenelistState);
  const navigate = useNavigate();
  return (
    <div className="flex flex-col p-5 text-white bg-black space-y-7">
      <img
        onClick={() => navigate("/")}
        className="mt-7 w-[60%] mx-auto max-w-[400px] p-5 bg-white bg-opacity-20 rounded-md object-contain cursor-pointer"
        src={logo}
        alt="logo"
      />
      <div className="text-2xl text-center">AR Scenes</div>
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {scenes.map((a) => (
          <div
            onClick={() => navigate(`/scene/${a.name}`)}
            key={a.name}
            className="flex flex-col items-center p-5 text-black bg-white border rounded-md shadow-lg "
          >
            <div className="flex items-center h-full ">
              <img className="object-contain" src={a.imageUrl} alt={a.label} />
            </div>
            <div className="flex-wrap p-3 text-lg lg:text-xl">{a.label}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
