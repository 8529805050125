// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";

const firebaseConfig = {
  apiKey: "AIzaSyDzGtkUvzBKlpxu0PLdePr5t9v0pGiHW00",
  authDomain: "sneakerai-af44b.firebaseapp.com",
  projectId: "sneakerai-af44b",
  storageBucket: "sneakerai-af44b.appspot.com",
  messagingSenderId: "146148982793",
  appId: "1:146148982793:web:aecf608341a852f9aab8d8",
  measurementId: "G-240BE5ZRM1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
const functions = getFunctions(app);
const perf = getPerformance(app);

export { db, storage, auth, functions, analytics, perf };
