import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { AnchorType } from "../typings/AnchorType";

const initialState: { anchors: AnchorType[] } = { anchors: [] };

const anchorlistSlice = createSlice({
  name: "anchorlist",
  initialState,
  reducers: {
    updateAnchorlist(state, action) {
      const sortedAnchors = [...action.payload].sort(
        (a, b) => a.order - b.order
      );
      state.anchors = sortedAnchors.filter((anchor) => anchor.active);
    },
  },
});

export const { updateAnchorlist } = anchorlistSlice.actions;

export const anchorlistState = (state: RootState) => state.anchorlist.anchors;

export default anchorlistSlice.reducer;
