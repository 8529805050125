import { useEffect, useRef, useState } from "react";
import QRScanner from "qr-scanner";

import { useSelector } from "react-redux";
import { anchorlistState } from "../redux/anchorlistSlice";
import { AnchorType } from "../typings/AnchorType";

import { ReactComponent as VolumeUpIcon } from "../assets/volume_up_FILL0_wght400_GRAD0_opsz48.svg";
import { ReactComponent as VolumeOffIcon } from "../assets/volume_off_FILL0_wght400_GRAD0_opsz48.svg";
import { ReactComponent as VideoStopIcon } from "../assets/stop_circle_FILL0_wght400_GRAD0_opsz48.svg";
import { ReactComponent as VideoNextIcon } from "../assets/skip_next_FILL0_wght400_GRAD0_opsz48.svg";
import { ReactComponent as VideoPrevIcon } from "../assets/skip_previous_FILL0_wght400_GRAD0_opsz48.svg";
import logo from "../assets/Sneakertopia-Logo-Complete-final-2019-08-14-W@4x.png";
import { useNavigate, useParams } from "react-router-dom";

type Props = {
  scan: boolean;
};
export default function Scanner({ scan }: Props) {
  const anchors = useSelector(anchorlistState);
  const [result, setResult] = useState<string>("");
  const [anchor, setAnchor] = useState<AnchorType | undefined>();
  const [showControls, setShowControls] = useState(true);
  const [filteredAnchors, setFilteredAnchors] = useState<AnchorType[]>([]);
  const [isMuted, setIsMuted] = useState(true);
  const navigate = useNavigate();
  const { docId } = useParams();

  console.log("is it scanner or video?", scan);

  function extractDocId(urlString: string) {
    const url = new URL(urlString);
    console.log("url===", url);
    console.log("url.pathname===", url.pathname);
    const pathParts = url.pathname.split("/");
    const lastPart = pathParts[pathParts.length - 1];
    console.log("lastPart===", lastPart);
    return lastPart;
  }

  useEffect(() => {
    console.log("docId useeffect===", docId);
    if (docId) setResult(docId);
  }, [docId]);

  useEffect(() => {
    if (scan) {
      const videoElem = document.getElementById("qr-video") as HTMLVideoElement;

      const qrScanner = new QRScanner(
        videoElem,
        (newResult) => {
          console.log("newResult===", newResult);
          setResult(extractDocId(newResult.data));
        },
        {
          highlightScanRegion: true,
          highlightCodeOutline: true,
        }
      );

      qrScanner.start();

      return () => {
        qrScanner.stop();
        qrScanner.destroy();
      };
    }
  }, [scan]);

  useEffect(() => {
    if (anchor) {
      const videoElem = document.getElementById(
        "play-video"
      ) as HTMLVideoElement;
      if (videoElem) {
        videoElem.src = anchor.videoUrl;
        videoElem.play();
      }
    }
  }, [anchor]);

  useEffect(() => {
    console.log("result in useEffect===", result);

    if (result) {
      const anchor = anchors.find((a) => a.name === result);
      if (anchor) {
        setAnchor(anchor);
        setFilteredAnchors(anchors.filter((a) => a.scene === anchor.scene));
      }
    }
  }, [anchors, result]);

  function findNext() {
    if (anchor && filteredAnchors.length > 0) {
      const index = filteredAnchors.findIndex((a) => a.name === anchor.name);
      setAnchor(
        index < filteredAnchors.length - 1
          ? filteredAnchors[index + 1]
          : index === filteredAnchors.length - 1
          ? filteredAnchors[0]
          : undefined
      );
    }
  }

  function findPrev() {
    if (anchor && filteredAnchors.length > 0) {
      const index = filteredAnchors.findIndex((a) => a.name === anchor.name);
      setAnchor(
        index > 0
          ? filteredAnchors[index - 1]
          : index === 0
          ? filteredAnchors[anchors.length - 1]
          : undefined
      );
    }
  }

  function toggleSound() {
    const video = document.getElementById("play-video") as HTMLVideoElement;
    if (video) {
      video.muted = !video.muted;
      setIsMuted(video.muted);
    }
  }

  function stopPlaying() {
    const video = document.getElementById("play-video") as HTMLVideoElement;
    if (video) {
      video.pause();
    }
    setAnchor(undefined);
    setResult("");
  }

  function startPlaying() {
    const video = document.getElementById("play-video") as HTMLVideoElement;
    if (video) {
      if (video.paused) {
        if (video.muted) {
          video.muted = false;
          setIsMuted(false);
        }
        video.play();
      }
    }
  }

  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);

  return (
    <div className="relative z-0 flex flex-col items-center justify-center w-screen h-screen bg-black">
      <div
        className={`absolute top-0 left-0 z-30 flex flex-col w-screen h-screen bg-black ${
          anchor ? "visible" : "invisible"
        }`}
      >
        <div className="relative w-full">
          <video
            ref={videoRef}
            id="play-video"
            autoPlay
            muted
            playsInline
            onEnded={stopPlaying}
            className={`object-cover w-screen h-screen`}
            // src={anchor?.videoUrl}
          />

          <div
            onClick={() => {
              startPlaying();
              setShowControls(!showControls);
            }}
            className="absolute top-0 z-40 flex flex-col items-center justify-center w-full h-full text-white"
          >
            {showControls && (
              <div className="flex flex-col items-center justify-between w-full h-full opacity-100">
                <img
                  onClick={() => {
                    stopPlaying();
                    navigate("/");
                  }}
                  className="w-[60%] m-5 max-w-[400px] p-5 bg-black bg-opacity-20 rounded-md object-contain cursor-pointer"
                  src={logo}
                  alt="logo"
                />

                <div className="px-5 py-3 mx-auto text-xl text-white bg-black rounded-md bg-opacity-20">
                  {anchor?.label}
                </div>

                <div className="flex flex-row items-center justify-between w-full p-5 mb-20">
                  <div className="flex items-center justify-center w-16 h-16 text-2xl bg-black rounded-md cursor-pointer bg-opacity-20">
                    <VideoPrevIcon
                      onClick={findPrev}
                      className="flex items-center justify-center"
                    />
                  </div>
                  <div className="flex space-x-2">
                    <div
                      onClick={toggleSound}
                      className="p-5 mx-auto text-xl text-white bg-black rounded-md cursor-pointer bg-opacity-20"
                    >
                      {isMuted ? (
                        <VolumeUpIcon className="flex items-center justify-center" />
                      ) : (
                        <VolumeOffIcon className="flex items-center justify-center" />
                      )}
                    </div>

                    <div
                      onClick={stopPlaying}
                      className="p-5 mx-auto text-xl text-white bg-black rounded-md cursor-pointer bg-opacity-20"
                    >
                      <VideoStopIcon className="flex items-center justify-center" />
                    </div>
                  </div>
                  <div className="flex items-center justify-center w-16 h-16 text-2xl bg-black rounded-md cursor-pointer bg-opacity-20">
                    <VideoNextIcon
                      onClick={findNext}
                      className="flex items-center justify-center"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="absolute top-0 left-0 w-screen h-screen mx-auto">
        <div className="flex flex-col items-center justify-between w-full h-full">
          <img
            onClick={() => {
              stopPlaying();
              navigate("/");
            }}
            className="z-20 w-[60%] m-5 max-w-[400px] p-5 bg-black bg-opacity-20 rounded-md object-contain cursor-pointer"
            src={logo}
            alt="logo"
          />
        </div>
      </div>

      {scan && (
        <div className="absolute top-0 left-0 z-10 w-screen h-screen">
          <video id="qr-video" className="object-cover w-full h-full" />
        </div>
      )}
    </div>
  );
}
